<template>
  <div class="text-center">
    <v-dialog v-model="dialog" max-width="800" persistent>
      <template v-slot:activator="{ props: activatorProps }">
        <v-btn @click="getWages()" v-bind="activatorProps" class="jogunSelect">
          W4C 임금총액
        </v-btn>
      </template>

      <v-card class="fontNotoSans400">
        <v-card-title class="fontNotoSans700 tac">
         <span style="opacity: 0.8;"> {{ formatYear(mainStartDate) }}년</span> <span style="color: #030202; font-size: 22px;">{{ formatCompName(mainComp) }} ({{ mainCompBizGubun }})</span> <span style="opacity: 0.8;"> W4C 임금총액 입력</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col v-for="month in 12" :key="month" cols="4">
                <v-text-field v-model="wageInputs[month - 1]" :label="`${month}월`" placeholder="임금총액 입력" type="text"
                  @focus="focusIn(month - 1)" @blur="focusOut(month - 1)" flat hide-details="auto" variant="outlined"
                  density="compact" class="align-right label-black" maxlength="11"
                  @input="onInput($event, month - 1)"></v-text-field>
              </v-col>
            </v-row>
              <div class="tar" style="margin-top: 10px;">
                <strong style="font-size: 18px;">총 합계: {{ addComma(sumRage) }} 원</strong>
              </div>
          </v-container>
        </v-card-text>
        <template v-slot:actions>
          <v-btn style="height: 50px; width: 100px; " @click="dialog = false" size="big">
            닫기
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn style="height: 50px; width: 100px;" @click="saveWages()" size="big">
            저장
          </v-btn>
        </template>
      </v-card>
    </v-dialog>
  </div>
</template>
<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import store from '@/store';
import axios from 'axios';
const wtwIdx = ref(null);
function formatCompName(str:string) {
  const regex = /\(([^)]+)\)/;
  const matches = str.match(regex);
  if (matches && matches.length > 1) {
    return str.replace(/\([^)]*\)/g, '').trim();
  } else {
    return;
  }
}
function addComma(number:number) {
    // 숫자가 아니거나 null인 경우 그대로 반환
    if (number === null || isNaN(number)) return number;

    // 숫자를 문자열로 변환 후 컴마 추가
    var strNumber = number.toLocaleString('en-US', {maximumFractionDigits: 2});

    // 소수점 이하가 모두 0인 경우 또는 정수인 경우, 소수점 이하를 제거
    return strNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const dialog = ref(false);
const wageInputs = ref(Array(12).fill(0));



// 포커스 인풋 값에서 컴마를 삭제하는 함수
// 인풋 값이 0일 때 포커스 시 0 삭제 함수
const focusIn = (index: any) => {
  if (wageInputs.value[index] === 0 || wageInputs.value[index] === '0') {
    wageInputs.value[index] = '';
  } else  {
    wageInputs.value[index] = wageInputs.value[index].replace(/,/g, '');
  }
};
//인풋 값이 아무것도 없을 때 0 다시 반환 함수
const focusOut = (index: any) => {
  if (wageInputs.value[index] === '' || !wageInputs.value[index]) {
    wageInputs.value[index] = 0;
  } else {
    // 포커스아웃 시 컴마 추가
    wageInputs.value[index] = numberWithCommas(wageInputs.value[index]);
  }
};
const numberWithCommas = (x: any) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
const onInput = (event: any, index: any) => {
  const inputValue = event.target.value;
  // 숫자만 남기고 나머지 문자 제거
  const numericValue = inputValue.replace(/\D/g, '');
  // 입력값 갱신
  wageInputs.value[index] = numericValue;
};

function formatYear(inputDate: any) {
  const date = new Date(inputDate);
  const year = date.getFullYear();
  return `${year}`;
}
//날짜,기관 변경 감지 함수 시작
const mainStartDate = ref('');
const mainEndDate = ref('');
const mainCompBizGubun = ref();
const mainComp = ref('');

const changeMainComp = computed(() => {
  const mainCompComputed = store.state.mainComp
  return mainCompComputed;
});

watch(changeMainComp, async (newValue: any) => {
  if (newValue) {
    mainComp.value = newValue;
  }
});

const changeBizGubun = computed(() => {
  const bizGubunComputed = store.state.mainCompBizGubun
  return bizGubunComputed;
});

watch(changeBizGubun, async (newValue: any) => {
  if (newValue) {
    mainCompBizGubun.value = newValue;
  }
});

// 레이아웃 메인데이트 변경 시, 감지
const setMainStartDate = computed(() => {
  const mainStartDateComputed = store.state.mainStartDate
  return mainStartDateComputed;
});

watch(setMainStartDate, async (newValue: any) => {
  if (newValue) {
    mainStartDate.value = newValue;
  }
});
const setMainEndDate = computed(() => {
  const mainEndDateComputed = store.state.mainEndDate
  return mainEndDateComputed;
});

watch(setMainEndDate, async (newValue: any) => {
  if (newValue) {
    mainEndDate.value = newValue;
  }
});


const saveWages = async () => {
  try {
    const valuesArray = Object.values(wageInputs.value);

    // 값들을 정리하고 정수로 변환
    const sanitizedValues = valuesArray.map(input => {
    // input이 0이거나 null일 경우 0을 반환
      if (input === 0 || input === null) {
        return 0;
      } else {
        // input이 문자열인 경우에만 replace 적용
        if (typeof input === 'string') {
          const sanitizedInput = parseInt(input.replace(/,/g, ''), 10); // 컴마를 제거하고 정수로 변환
          return isNaN(sanitizedInput) ? 0 : sanitizedInput; // NaN일 경우 0으로 대체
        }
        return input; // 문자열이 아닐 경우 그대로 반환
      }
    });

    // 처리된 값을 다시 wageInputs.value에 저장
    wageInputs.value = sanitizedValues;

    const response = await axios.post(`/api/saveWages/${mainComp.value}/${mainCompBizGubun.value}/${formatYear(mainStartDate.value)}/${wtwIdx.value}`, { form: wageInputs.value });

    const responseData = response.data;
    if (responseData.success === true) {
      await getWages();
      alert('저장이 완료되었습니다.');

    } else {
      console.error('에러가 발생했습니다.', responseData);
    }
  } catch (error) {
    console.error('errorMsg:', error);
  }
};


let sumRage = ref(0);

const getWages = async () => {
  try {
    const response = await axios.get(`/api/getWages/${mainComp.value}/${mainCompBizGubun.value}/${formatYear(mainStartDate.value)}`);
    const responseData = response.data;

    if (responseData) {
      const { idx, ...filteredData } = responseData; // idx와 나머지 데이터를 분리

      // wageInputs.value에 나머지 값들을 배열로 저장
      wageInputs.value = Object.values(filteredData);
      wtwIdx.value = idx;

      sumRage.value = 0;

      for(var i=0; i<12; i++){
        sumRage.value += parseFloat(wageInputs.value[i].replace(/,/g, ''))
      }
      console.log(sumRage.value,'sumRage.value')
      return responseData; // 가져온 데이터를 반환
    } else {
        wageInputs.value = [0,0,0,0,0,0,0,0,0,0,0,0];
        console.error('에러가 발생했습니다.', responseData);
    }
    
  } catch (error) {
    console.error('errorMsg:', error);
  }
};

onMounted(async () => {
  mainStartDate.value = store.state.mainStartDate;
  mainEndDate.value = store.state.mainEndDate;
  mainComp.value = store.state.mainComp;
  mainCompBizGubun.value = store.state.mainCompBizGubun;
})
//날짜,기관 변경 감지 함수 끝
</script>